import React from "react"
import styled from "styled-components";

const FlexContainer = ({children}) => (<StyledDiv>{children}</StyledDiv>);

const StyledDiv=styled.div`
  display:fiex;
  flex-wrap:wrap;
  justify-content:center;
`

export default FlexContainer

