import React, {useEffect, useRef} from "react"
import styled from "styled-components";

//SNSボタンをReactのサイトに埋め込む
//https://blog.kwst.site/201907180147/
const NiconicoPlayer = ({id,title}) => {
  const wrapper = useRef(null);
  useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://embed.nicovideo.jp/watch/"+id+"/script?w=640&h=360";
      script.async = true;
      script.defer = true;
      (wrapper.current).appendChild(script);
    
  }, []);
  
  return <div><StyledDiv ref={wrapper}/></div>;
}
const StyledDiv=styled.div`
  max-width:640px;
  height:360px;
  margin:0 auto;
`

export default NiconicoPlayer

