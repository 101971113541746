import React from "react"
import RandomFlex from "../../Container/RandomFlex";
import DLsiteCard from "../../DLsite/Card";

const CoeAvatarCards = ({limit}) =>
{
  const adItems = 
  [
    "VJ015358",
    "VJ015438",
    "VJ015586",
  ]
  .map((item) => (<DLsiteCard store="soft" item={item}/>));
  return (<RandomFlex limit={limit}>{adItems}</RandomFlex>);
};

export default CoeAvatarCards

