import React from "react"
import styled from "styled-components";
import Flex from "../Flex";

const RandomFlexContainer = ({children, limit}) =>
{
  const randomChildren = children
    .map((child) => ({ child, randomNum: Math.random() }))
    .sort((a, b) => a.randomNum - b.randomNum)
    .slice(0, limit)
    .map(({ child }) => child);
  return (<Flex>{randomChildren}</Flex>);
};


export default RandomFlexContainer

