import React from "react"

const NiconicoCard = ({id,title}) => {
  return (
    <iframe 
      width="312" 
      height="176"
       src={"https://ext.nicovideo.jp/thumb/"+id} 
       scrolling="no" 
       style={{border:"solid 1px #ccc"}}
       frameBorder="0">
         <a href={"https://www.nicovideo.jp/watch/"+id}>{title}</a>
    </iframe>
  )
}

export default NiconicoCard

