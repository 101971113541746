import React from "react"
import RandomFlex from "../../Container/RandomFlex";
import DLsiteCard from "../../DLsite/Card";

const VOICEROIDCards = ({limit}) =>
{
  const adItems = 
  [
    "VJ014422",//VOICEROID2 音街ウナ
    "VJ013232",//VOICEROID2 伊織弓鶴
    "VJ013000",//VOICEROID2 ついなちゃん
    "VJ012169",//VOICEROID2 東北イタコ
    "VJ011987",//VOICEROID2 桜乃そら
    "VJ011531",//VOICEROID2 紲星あかり
    "VJ011116",//VOICEROID2 琴葉 茜・葵
    "VJ011115",//VOICEROID2 結月ゆかり
    "VJ010525",//VOICEROID+ 東北きりたん EX
    "VJ010224",//VOICEROID+ 京町セイカ EX
    "VJ009577",//VOICEROID+ 水奈瀬コウ EX
    "VJ008847",//VOICEROID+ 東北ずん子 EX
    "VJ008845",//VOICEROID+ 鷹の爪 吉田くん EX
    "VJ008844",//VOICEROID+ 民安ともえ EX
    "VJ008843",//VOICEROID+ 月読ショウタ EX
    "VJ008842",//VOICEROID 月読アイ
  ]
  .map((item) => (<DLsiteCard store="soft" item={item}/>));
  return (<RandomFlex limit={limit}>{adItems}</RandomFlex>);
};

export default VOICEROIDCards

