import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const GetSlug = (dir,path)=> (dir+"/"+path+"/").replace("//","/").replace(/\/.+\/\.\.\//,"/");
const GetTitle = (data, slug)=>data.allMdx.nodes.find(x=>x.fields.slug === slug)?.frontmatter.title ?? "NotFound";

class IndexRoot extends Component{
  state={
    dir:null,
    target:null,
    depth:null,
    prefix:null
  }
  constructor(props){
    super(props);
    this.state={
      dir:props.dir,
      target:props.target,
      depth:props.depth,
      prefix:props.prefix,
    }
  }
  render(){
    return (
    <StaticQuery 
      query={graphql`
        query indexAndTitleQuery {
          allIndexYaml {
            nodes {
              key
              index {
                page
              }
            }
          },
          allMdx {
            nodes {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
        `}
      render={data=>(
        IndexList(
          data,
          this.state.dir,
          this.state.target,
          this.state.depth,
          this.state.prefix
        ))}/>)
  }
}
const IndexList = (data,dir,target,depth,prefix)=>{
  const pages = data.allIndexYaml.nodes.find(node=>node.key===dir);
  if(!pages || pages.index.length===0) return null;

  const isTargetParent = target.startsWith(dir);
  const isTargetChild  = dir.startsWith(target); 
  if(!isTargetParent && !isTargetChild) return null;
  if(isTargetChild) depth--;
  if(depth < 0) return null;

  return (
  <ul>
    {pages.index.map(x=>IndexItem(data,dir,x.page,target,depth,prefix))}
  </ul>);
}
const IndexItem = (data,dir,page,target,depth,prefix)=>{
  const slug = GetSlug(dir,page);
  const isTarget = target === slug;
  return (<li key={prefix+slug} className={isTarget ? "current":null}><Link to={slug}>{GetTitle(data,slug)}</Link>{IndexList(data,slug,target,depth)}</li>)
}
export default IndexRoot;
