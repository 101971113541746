import React from "react"
import storage from "local-storage-fallback"


class YMM4Screenshot extends React.Component {
  state =
  {
    isKawaiiMode: false,
  }
  componentDidMount() {
    //urlに?kawaii=trueを追加すると強制的に可愛いモードになる
    const url = new URL(window.location.href)
    const urlKawaiiFlag = url.searchParams.get("kawaii")
    if(urlKawaiiFlag !== null)
    {
      if(urlKawaiiFlag === "" || urlKawaiiFlag === "true" || urlKawaiiFlag === "1")
      {
        this.setState({ isKawaiiMode: "true" })
        storage.setItem("kawaiiMode", "true" )
      }
      else
      {
        this.setState({ isKawaiiMode: "false" })
        storage.setItem("kawaiiMode", "false" )
      }
    }

    //ローカルストレージに保存された値を読み込む
    const kawaiiMode = storage.getItem("kawaiiMode")
    this.setState({ isKawaiiMode: kawaiiMode === "true" })
  }
  handleToggle = event => {
    const kawaiiMode = !this.state.isKawaiiMode
    this.setState({ isKawaiiMode: kawaiiMode })
    storage.setItem("kawaiiMode", kawaiiMode)

    event.preventDefault()
    event.stopPropagation()
  }

  //可愛いモードのときは可愛い画像"/KawaiiYMM4BySawaratsuki.png"そうでない場合は"/YMM4Screenshot.png"を表示する
  //画像をマウスオーバーで画像を拡大する
  //画像をマウスクリックで画像を変更する
  //可愛い画像と普通の画像両方のimgを用意しておき、モード切替時にフェードで切り替える
  //可愛いモードの場合は画像右下にLogo by さわらつきを表示する
  //画像は中央寄せする
  render() {
    return (
      <div style={{
        textAlign: "center",
        position: "relative",
      }}>
        <img
          src="/KawaiiYMM4BySawaratsuki.png"
          alt="YMM4 Kawaii logo by さわらつき"
          className="ignore-zoom"
          style={{
            opacity: this.state.isKawaiiMode ? 1 : 0,
            pointerEvents: this.state.isKawaiiMode ? "auto" : "none",
            transition: "opacity 0.5s, transform 0.5s",
            cursor: "pointer",
          }}
          onMouseEnter={e => e.target.style.transform = "scale(1.1)"}
          onMouseLeave={e => e.target.style.transform = "scale(1)"}
          onClick={this.handleToggle}
          draggable="false"
        />
        <img
          src="/YMM4Screenshot.png"
          alt="YMM4 Screenshot"
          className="ignore-zoom"
          style={{
            marginTop: "-100%",
            opacity: this.state.isKawaiiMode ? 0 : 1,
            pointerEvents: this.state.isKawaiiMode ? "none" : "auto",
            transition: "opacity 0.5s, transform 0.5s",
            cursor: "pointer",
          }}
          onMouseEnter={e => e.target.style.transform = "scale(1.1)"}
          onMouseLeave={e => e.target.style.transform = "scale(1)"}
          onClick={this.handleToggle}
          draggable="false"
        />

        {this.state.isKawaiiMode && (
          <div
            style={{
              marginTop: "-1.5em",
              fontSize: "0.7em",
              textAlign: "right",
            }}
          >
            Logo by <a href="https://twitter.com/sawaratsuki1004" target="_blank" rel="noopener noreferrer">さわらつき</a>
          </div>
        )}
      </div>
    )
  }
}

export default YMM4Screenshot
