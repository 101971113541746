import React from "react"
import RandomFlex from "../../Container/RandomFlex";
import DLsiteCard from "../../DLsite/Card";

const CeVIOCards = ({limit}) =>
{
  const adItems = 
  [
    "VJ014645",//さとうささら
    "VJ015600",//すずきつづみ
    "VJ01000829",//タカハシ

    "VJ014974",//小春六花
    "VJ015665",//花隈千冬
    "VJ015248",//夏色花梨

    "VJ014834",//ONE
    "VJ014833",//IA

    "VJ014975",//弦巻マキ
    "VJ014994",//ロサ
    "VJ014895",//フィーちゃん
    "VJ01001301",//双葉奏音
    "VJ01001516",//ユニちゃん
  ]
  .map((item) => (<DLsiteCard store="soft" item={item}/>));
  return (<RandomFlex limit={limit}>{adItems}</RandomFlex>);
};

export default CeVIOCards

