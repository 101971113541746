import React from "react"
import styled from "styled-components";

const DLsiteCard = ({store, item}) => {
  const id = "manju";
  
  const isZero = item.substring(item.length-3, item.length) == "000";
  const keta = item.substring(2, item.length).length;
  const imageDir = isZero ? item : item.substring(0,2) + (parseInt(item.substring(2, item.length - 3))+1).toString().padStart(keta-3, "0") + "000";
  
  const type = item.substring(0,2) == "RJ" ? "doujin" : "professional";
  return (
  <StyledDiv>
    <a target="_blank" rel="noopener sponsored" href={"https://www.dlsite.com/"+store+"/dlaf/=/t/i/link/work/aid/"+id+"/id/"+item+".html"}>
      <img border="0" src={"//img.dlsite.jp/modpub/images2/work/"+type+"/"+imageDir+"/"+item+"_img_main.jpg"} />
    </a>
  </StyledDiv>);
}
const StyledDiv=styled.div`
  height:160px;
  width:160px;
  margin:10px 0;

  a{
    height:100%;
    width:100%;
    display:block;
    
    img{
      margin:0;
      height:100%;
      width:100%;
      object-fit:contain;
    }
  }
`

export default DLsiteCard

