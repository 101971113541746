import React from "react"
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const Download = ({children, url}) => {
  
  return (
    <>
      <StyledDiv>
        <button onClick={()=>
          {
            if(window && window.gtag){
              window.gtag('event', 'Download', {
                'event_category': 'File',
                'event_label': url,
                'value': 1
              })
            }
            window.location=url
          }} rel="nofollow">
          <FontAwesomeIcon className="icon-fa fa-download" icon={faDownload}/>
          {children}
        </button>
      </StyledDiv>
    </>
  );
}

const StyledDiv=styled.div`
  button,a{
    color:white !important;
    font-size: 1rem;

    display:inline-block;
    background-color: DodgerBlue;
    border: none;
    margin:0.5rem 0;
    padding: 0.7rem 1.2rem;
    cursor: pointer;
    border-radius:0.25rem;

    :hover {
      background-color: #3eb0ff;
    }
  }
  .fa-download{
    margin-right:0.7rem;
  }
`

export default Download

