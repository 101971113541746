import React from "react"
import RandomFlex from "../../Container/RandomFlex";
import DLsiteCard from "../../DLsite/Card";

const VOICEPEAKCards = ({limit}) =>
{
  const adItems = 
  [
    "VJ015169",//VOICEPEAK 商用可能 6ナレーターセット
    "VJ015808",//東北ずん子
    //"VJ015809",//フリモメン
    "VJ015810",//彩澄しゅお
    "VJ015811",//彩澄りりせ
    "VJ01000358",//邪神ちゃん
    "VJ01000513",//東北きりたん
    "VJ01000661",//小春六花
    "VJ01000762",//桜乃そら
    "VJ01000872",//音街ウナ
    "VJ01000938",//東北イタコ
    "VJ01000939",//京町セイカ
    "VJ01001230",//音楽的同位体 星界 TALK
    "VJ01001352",//音楽的同位体 狐子 TALK
    "VJ01001356",//弦巻マキ
    "VJ01001357",//宮舞モカ
    "VJ01001353",//音楽的同位体 裏命 TALK
    "VJ01002100",//音楽的同位体 羽累 TALK
    "VJ01002626",//大江戸ちゃんこ
    "VJ01002627",//水奈瀬コウ
    "VJ01002628",//水奈瀬リト
  ]
  .map((item) => (<DLsiteCard store="soft" item={item}/>));
  return (<RandomFlex limit={limit}>{adItems}</RandomFlex>);
};

export default VOICEPEAKCards

