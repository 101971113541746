import React, { Component } from "react"
import "./styles.scss"
import styled from "styled-components";
import { theme } from "../Shared/styles-global"
import { setThemeVars } from "../../util/theme-helper"
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Warning from "../MdxComponents/TextBlock/Warning"
import Success from "../MdxComponents/TextBlock/Success"
import { StaticQuery, graphql, Link } from "gatsby"
import { Info } from "../MdxComponents/"

class ContactForm extends Component {
    pubkey="6Ld3PoAUAAAAANsNKCo3idooFu8K2OXKjmuhDnsU";
    
    state={
        isSending:false,
        type:null,
        email:null,
        message:null,
        file1:null,
        file2:null,
        file3:null,
        result:null,
    };
    constructor(props){
        super(props);

        this.state = {
            type:"ymm4",
            email:this.getCookieEmail(),
            message:null
        }
    }
    getCookieEmail = () => {
        if (typeof document == `undefined`) return null;
        return (
            document.cookie
            ?.split(';')
            ?.find(x=>x.startsWith("email="))
            ?.split('=')[1]);
    }
    onTypeChanged = (e)=>{
        this.setState({type: e.target.value});
    }
    onEMailAddressChanged =(e)=>{
        this.setState({email: e.target.value});
    }
    onMessageChanged = (e)=>{
        this.setState({message: e.target.value});
    }
    onFile1Changed = (e)=>{
        this.setState({file1: e.target.files[0]});
    }
    onFile2Changed = (e)=>{
        this.setState({file2: e.target.files[0]});
    }
    onFile3Changed = (e)=>{
        this.setState({file3: e.target.files[0]});
    }
    onVerify =(token)=>{
        this.setState({token:token});
    }
    submit = async (e) => {
        e.preventDefault();
        document.cookie="email=" + this.state.email;
        this.setState({isSending:true,result:null});
        
        const data = new FormData(e.target);
        data.append("token",this.state.token);
        
        const xhr = new XMLHttpRequest();
        xhr.open("POST","/php/contact.php",true);
        xhr.send(data);
        xhr.onreadystatechange = ()=>{
            if(xhr.responseText == "success"){
                this.setState({result:xhr.responseText,isSending:false,message:""});
            }else{
                this.setState({result:xhr.responseText,isSending:false});
            }
        }

    }
    render(){
        return ( 
            <>
            <StyledForm className="contactForm" onSubmit={this.submit}>
                <label>
                    <select name="type" id="contact-form-type" value={this.state.type} onChange={this.onTypeChanged}>
                        <option value="ymm4">ゆっくりMovieMaker4</option>
                        <option value="ymm3">ゆっくりMovieMaker3</option>
                        <option value="web">Webサイト</option>
                        <option value="other">その他</option>
                    </select>
                    に関するお問い合わせ
                </label>

                <label>
                    メールアドレス（必須）
                    <input type="email" required={true} name="reply" id="contact-form-email" value={this.state.email} onChange={this.onEMailAddressChanged}/>
                </label>

                <label>
                    メッセージ本文（必須）
                    <textarea required={true} name="msg" value={this.state.message} onChange={this.onMessageChanged}/>
                </label>

                <label className="file-label1">
                    添付ファイル
                    <input type="file" name="file1" onChange={this.onFile1Changed}/>
                </label>
                <label className="file-label2">
                    <input type="file" name="file2" onChange={this.onFile2Changed}/>
                </label>
                <label className="file-label3">
                    <input type="file" name="file3" onChange={this.onFile3Changed}/>
                </label>
                
                <StaticQuery
                        query={graphql`
                            query {
                                allMdx(filter: {frontmatter: {tags: {eq: "YMM4"}}}) {
                                    edges {
                                    node {
                                        fields {
                                        slug
                                        }
                                        frontmatter {
                                        title
                                        keywords
                                        }
                                    }
                                    }
                                }
                            }
                        `}
                        render={data =>
                            {
                                const posts = data.allMdx.edges
                                    .filter(({ node }) => node.frontmatter.keywords != null)
                                    .map(({ node }) => {
                                    let hitCount = this.state.message == null ? 0 : node.frontmatter.keywords.filter(keyword=>this.state.message.toLowerCase().includes(keyword.toLowerCase())).length;
                                    return {node:node,hitCount:hitCount};
                                })
                                .filter(({ hitCount }) => hitCount > 0)
                                .sort((a,b)=>b.hitCount-a.hitCount)
                                .slice(0,4);

                                if(posts.length == 0) 
                                    return null;
                                else
                                    return (
                                        <Info>
                                            <b>もしかして：</b>
                                            <ul>
                                                {posts.map(({ node}) => (
                                                    <li key={node.fields.slug}>
                                                        <a href={node.fields.slug} target="_blank">
                                                            {node.frontmatter.title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Info>
                                    );
                            }
                        }
                    />
                
                <GoogleReCaptchaProvider reCaptchaKey={this.pubkey}>
                    {this.state.isSending?null:<GoogleReCaptcha onVerify={this.onVerify} />}
                </GoogleReCaptchaProvider>

                <button disabled={this.state.isSending}>{this.state.isSending?"送信中..." : "送信"}</button>
            </StyledForm>
            {this.state.result == null ? null : this.state.result === "success" ? <Success>送信しました</Success>:<Warning>送信に失敗しました<br/>{this.state.result}</Warning>}
            </>
        );
    }
}
const StyledForm = styled.form`
    select{
        color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
        background:${()=>setThemeVars( theme.bgColorLight,theme.bgColorDark)};
    }
    input[type=email]{
        color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
        background:${()=>setThemeVars( theme.bgColorLight,theme.bgColorDark)};
        border:solid 1px ${()=>setThemeVars( theme.fontSubColorLight,theme.fontSubColorDark)};
    }
    textarea{
        color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
        background:${()=>setThemeVars( theme.bgColorLight,theme.bgColorDark)};
        border:solid 1px ${()=>setThemeVars( theme.fontSubColorLight,theme.fontSubColorDark)};
    }
    button{
        color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
        background:${()=>setThemeVars( "#eeeeee","#222224")};
        border:solid 1px ${()=>setThemeVars( theme.fontSubColorLight,theme.fontSubColorDark)};

        :hover{
            background:${()=>setThemeVars( "#dddddd","#111112")};
        }
    }
`
export default ContactForm;