import React from "react"
import IndexRoot from "./IndexRoot/"
import { globalHistory } from "@reach/router"
import styled from "styled-components"
import { theme } from "../Shared/styles-global"
import { setThemeVars } from "../../util/theme-helper"

const Index = ({slug})=>{
  return (<StledNav><IndexRoot dir={slug} target={slug} depth={2} prefix="index"/></StledNav>)
}
const StledNav = styled.nav`
  >ul{
    >li>a{
      color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
      font-size:1.1rem;
    }
    >li{
      margin-top:0.6rem !important;
      border-bottom:solid 1px ${()=>setThemeVars( theme.separatorColorLight,theme.separatorColorDark)}; !important;
    }
  }
  ul{
    margin:0 0 0.6rem 1rem !important;
  }
  li{
    display: block !important;
  }
`
export default Index;
