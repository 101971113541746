import React from "react"
import styled from "styled-components";

const Address = ({acc,dmy,dmn}) => {
  return (<span><span>{acc}</span><Omajinai>{dmy}</Omajinai><span>&#x40;</span><span>{dmn}</span></span>);
}

const Omajinai=styled.span`
  display:none;
`
export default Address

