import React from "react"
import styled from "styled-components";

const AmazonCard = ({item}) => {
  const unique=[...Array(32)].map(()=>Math.floor(Math.random()*16).toString(16)).join();
  const id = "manjubox-22";
  return (
  <StyledDiv>
    <a target="_blank" href={"https://www.amazon.co.jp/gp/product/"+item+"/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN="+item+"&linkCode=as2&tag="+id+"&linkId="+unique}>
      <img border="0" src={"//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=JP&ASIN="+item+"&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL160_&tag="+id} />
      </a>
      <img src={"//ir-jp.amazon-adsystem.com/e/ir?t="+id+"&l=am2&o=9&a="+item} width="1" height="1" border="0" style={{border:"none", margin:"0px", width:"1px", height:"1px"}} />
  </StyledDiv>);
}
const StyledDiv=styled.div`
  height:160px;
  width:160px;
  margin:10px 0;
`

export default AmazonCard

